import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import DefaultLayout from "../components/layouts/default";
import { useHeader } from "../layouts/headers/header-atom";
type Event = {
  id: number;
  title: string;
  date: string;
  time: string;
  posterUrl?: Array<string>;
  imageURL: string;
  className?: string;
  description: string;
};

const events: Event[] = [
  {
    id: 15,
    title: "Dinner & joy",
    date: "",
    time: "",
    imageURL:
      "https://drive.google.com/thumbnail?id=1JPLFwXOK717Eww288sxVQ3ywFTyWoJCs&sz=w1000&sz=w1000",
    description: "Muziekbingo, Albert & Hein, Duo sal. Come and enjoy",
    posterUrl: [
      "https://drive.google.com/thumbnail?id=1JPLFwXOK717Eww288sxVQ3ywFTyWoJCs&sz=w1000",
    ],
  },
  {
    id: 16,
    title: "Muziek bingo",
    date: "Zondag, 22 December",
    time: "Vanaf 21:00",
    imageURL:
      "https://drive.google.com/thumbnail?id=1VLuFirwtGjJYTm21abOJstr4ogqGIJz7&sz=w1000",
    description: "Muziekbingo Kersteditie",
    posterUrl: [
      "https://drive.google.com/thumbnail?id=1VLuFirwtGjJYTm21abOJstr4ogqGIJz7&sz=w1000",
    ],
  },
  {
    id: 17,
    title: "New year brunch",
    date: "Woensdag, 1 Januari",
    time: "12u - 16u",
    imageURL:
      "https://drive.google.com/thumbnail?id=1fTV8b8MLTkGc7Y-z9yTnqrxgVUUuBI2H&sz=w1000",
    description:
      "Celebrarte new year with Bistro Papillon + Nieuwjaarsduik 16U",
    posterUrl: [
      "https://drive.google.com/thumbnail?id=1fTV8b8MLTkGc7Y-z9yTnqrxgVUUuBI2H&sz=w1000",
    ],
  },
  {
    id: 17,
    title: "Albert & Hein, Duo sal",
    date: "29/12 & 03/01",
    time: "vanaf 19:30",
    imageURL:
      "https://drive.google.com/thumbnail?id=1Folp5QDSBs9Cb1s546y8rTjPG6bsdcLf&sz=w1000",
    description:
      "Kom feesten op de muziek van ALbert & Hein op 29/12 of Duo sal op 03/01",
    posterUrl: [
      "https://drive.google.com/thumbnail?id=1Folp5QDSBs9Cb1s546y8rTjPG6bsdcLf&sz=w1000",
    ],
  },
];

// {
//     link: "/events",

//     title: "Nieuwjaars Brunch",
//     image: 'https://plus.unsplash.com/premium_photo-1701194079160-b0995498e526?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//     description: "Maandag, 1 Januari: All-in Brunch + Nieuwjaarsduik!"
// },

// {
//     link: "/events",

//     title: "Quiz & Bites",
//     image: 'https://drive.google.com/uc?id=1Mj-T1rWXj6K2RH-thPILVqgkIzQTgBil',
//     className: " object-top",
//     description: "Zaterdag, 20 Januari: Heb jij het in je om de ultieme Papillon Quiz kampioen te worden?"
// },

type EventCardProps = {
  event: Event;
};

function EventCard({ event }: EventCardProps) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
        <div className="relative h-48">
          <img
            className={
              "absolute inset-0 w-full h-full object-cover cursor-pointer" +
              event.className
            }
            src={event.imageURL}
            alt={event.title}
            onClick={event.posterUrl && openModal}
          />
        </div>
        <div className="p-4 flex-grow">
          <h3 className="text-lg font-medium text-gray-900">{event.title}</h3>
          <p className="text-sm font-medium text-gray-500">
            {event.date} {event.time && "|"} {event.time}
          </p>
          <p className="mt-2 text-sm text-gray-500">{event.description}</p>
        </div>
        {event.posterUrl && event.posterUrl.length > 0 && (
          <div className="bg-gray-50 px-4 py-3 bottom-0">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-accent hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
              onClick={openModal}
            >
              Volledige poster
            </button>
          </div>
        )}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto top-0">
            <div className="flex items-top pt-40 justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full top-0 px-10 md:w-6/12 md:px-0  transform  rounded-2xl bg-white p-6 text-left shadow-xl transition-all">
                  <div className="absolute top-0 inset-0 w-full">
                    {event.posterUrl &&
                      event.posterUrl.map((url) => (
                        <img
                          className=" top-0 inset-0 w-full object-fill height-auto object-top
                                            cursor-pointer"
                          key={url}
                          src={url}
                          alt={event.title}
                          onClick={openModal}
                        />
                      ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default function Events() {
  const { showHeader } = useHeader();
  showHeader();
  return (
    <DefaultLayout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 pt-40">
        {/* <div className="pb-5">
                    <EventCard key={"live music"} event={{
                        id: 14,
                        title: "Halloween komt er aan!",
                        date: '28 en 29 Oktober halloween wandeling en brunch',
                        time: '',
                        imageURL: "https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
                        description: ""
                    }} />
                </div> */}
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
          Opkomende evenementen
        </h2>
        <div className="mt-6 grid gap-5 lg:grid-cols-2 xl:grid-cols-3">
          {events.map((event) => (
            <EventCard key={event.id} event={event} />
          ))}
        </div>
      </div>
    </DefaultLayout>
  );
}
