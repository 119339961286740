import { siteSettings } from '../../config/site';
import Link from '../ui/link';
// import SubscriptionWidget from '@/components/settings/subscribe-to-newsletter';

const Footer = () => {
  return (
    <div className="flex w-full flex-col border-gray-800 bg-white px-5 md:px-10 lg:border-b-8 lg:px-[50px] xl:px-16">
      {/* Top */}
      <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(260px,1fr))] gap-6 pt-16 md:grid-cols-3 lg:pt-24 lg:pb-16 xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))] xl:gap-8 2xl:grid-cols-4">
        <div className="flex flex-col justify-center items-start">
          <div className="mb-[2px] flex h-32 items-start ">
            <span className="relative h-32 w-32 overflow-hidden md:w-32">
              <img
                className=' object-contain object-center'
                src={"/Logo-papillon.webp"}
                alt={"Bistro papillon"}
                loading="eager"
              />
            </span>
          </div>
          <h3 className="mt-3  font-semibold text-gray-900 ">
            {"Adres"}
          </h3>
          <address className="mb-7 text-sm not-italic text-black mt-0">
            {siteSettings.footer.address}
          </address>
          <h3 className="mt-3 font-semibold text-gray-900">
            {"Contact"}
          </h3>
          <span className="mb-1 text-sm text-black">
            {siteSettings.footer.email}
          </span>
          <span className="text-sm text-black">
            {siteSettings.footer.phone}
          </span>
        </div>
        {siteSettings.footer.menus.map((menu, idx) => (
          <div className="flex flex-col" key={`${menu.title}-${idx}`}>
            <h3 className="mt-3 mb-4 font-semibold text-gray-900 lg:mb-7">
              {menu.title}
            </h3>
            <ul className="space-y-3">
              {menu.links.map((link, index) => (
                <li key={`${link.href}-${index}`}>
                  <Link
                    target={link.href}
                    className="text-sm text-gray-900 transition-colors hover:text-accent"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className="flex flex-col">
          <h3 className="mt-3 mb-4 font-semibold text-gray-900 lg:mb-7">
            {"Openingsuren"}
          </h3>
          <div className="flex justify-center">
            <ul className="space-y-3 pr-10">
              {siteSettings.footer.openingHours.map((obj, index) => (
                <li key={`day-${index}`} className="flex justify-between w-full flex-grow">
                  {["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"][index]}

                </li>
              ))}
            </ul>
            <ul className="space-y-3">
              {siteSettings.footer.openingHours.map((obj, index) => (
                <li key={`day-${index}`} className="flex justify-between w-full flex-grow">
                  {obj.closed && "Gesloten"}
                  {obj && !obj.closed && obj.from}
                  &nbsp;{!obj.closed && "-"}&nbsp;
                  {obj && !obj.closed && obj.to}
                </li>
              ))}
              {/* <li key={`toekomst`} className="flex justify-between w-full flex-grow">
                Vanaf 1 April 2024 weer alle dagen geopend.


              </li> */}

            </ul>
          </div>
        </div>
      </div>
      {/* Bottom */}
      <span className="flex text-sm text-gray-900 mb-5 mt-10">
        &copy; {"CopyRight"} {new Date().getFullYear()}&nbsp;
        <p
          className="font-bold text-gray-900"
        >
          {siteSettings.footer.copyright.name}
        </p>&nbsp;
        {"Alle rechten voorbehouden"}
      </span>
    </div >
  );
};

export default Footer;
