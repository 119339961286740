
export const siteSettings = {
    name: 'Bistro papillon',
    description: '',
    logo: {
        url: '/Logo-papillon.webp',
        alt: 'bistropapillon',
        href: '/',
        width: 128,
        height: 40,
    },
    defaultLanguage: 'nl',

    footer: {
        copyright: {
            name: 'Bistro papillon',
        },
        address: 'Steyvershofstraat 14, 3640 Kinrooi (BE)',
        email: 'bistro@papillonkinrooi.be',
        phone: '+32 456 269401',
        openingHours: [{ from: "16:00", to: "21:00", closed: false }, { from: "12:00", to: "21:00", closed: true }, { from: "16:00", to: "21:00", closed: true }, { from: "16:00", to: "21:00" }, { from: "16:00", to: "21:00" }, { from: "12:00", to: "22:00" }, { from: "12:00", to: "22:00" }],
        menus: [
            {
                title: "Pagina's",
                links: [
                    {
                        name: 'Hoofdpagina',
                        href: '/',
                    },
                    {
                        name: 'Over ons',
                        href: '/about',
                    },
                    {
                        name: 'Menu',
                        href: '/menu',
                    },
                    {
                        name: 'Evenementen',
                        href: '/events',
                    },
                    {
                        name: 'Contact',
                        href: '/contact',
                    },
                    {
                        name: 'Foto Galerij',
                        href: '/galery',
                    },
                    {
                        name: 'Vacatures',
                        href: '/vacatures',
                    },
                ],
            },


        ],

    },
};
